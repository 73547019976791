import React from 'react';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTheme } from '@material-ui/styles'; 

// import Link from 'next/link'; 
import Link from 'components/Link'; 
import Box from '@material-ui/core/Box';  
import Copyright from 'widgets/Copyright';  
import Typography from '@material-ui/core/Typography';  


export default function Header() {   
  const theme = useTheme(); 
  const matchesSm = useMediaQuery(theme.breakpoints.up('sm'));  

  return (
    <Box
      className={matchesSm ? 'footerSm' : 'footerXs'}
      mt={4} >
    	<div className={matchesSm ? 'footerBottomMenuSm' : 'footerBottomMenuXs'}>
    		<section className={'footerMenuSection'}>
	    		<Typography className={'footerMenuSectionTitle'} variant="button" display="block">
		        ABOUT
		      </Typography> 
	    		<ul className={'footerMenuUl'}>
	    			<li className={'footerMenuItem'}>
	    				<Link className={'footerMenuLink'} href="/about">About {process.env["NEXT_PUBLIC_COMPANY_NAME"]}</Link>
	  				</li> 
            <li className={'footerMenuItem'}>
              <Link className={'footerMenuLink'} href="/careers">Careers</Link>
            </li>    
					</ul> 
				</section> 
        <section className={'footerMenuSection'}>
          <Typography className={'footerMenuSectionTitle'} variant="button" display="block">
            COMMUNITY
          </Typography> 
          <ul className={'footerMenuUl'}>
            <li className={'footerMenuItem'}>
              <Link className={'footerMenuLink'} href="/diversity">Diversity & Belonging</Link>
            </li> 
            <li className={'footerMenuItem'}>
              <Link className={'footerMenuLink'} href="/terms">Terms of Service</Link>
            </li> 
          </ul>
        </section> 
        <section className={'footerMenuSection'}>
          <Typography className={'footerMenuSectionTitle'} display="block">
            PARTNERS
          </Typography> 
          <ul className={'footerMenuUl'}> 
            <li className={'footerMenuItem'}>
              <Link className={'footerMenuLink'} href="/become-a-partner">Become a Partner</Link>
            </li> 
            <li className={'footerMenuItem'}>
              <Link className={'footerMenuLink'} href="/sitemap/1">Sitemap</Link>
            </li> 
          </ul>
        </section> 
        <section className={'footerMenuSection'}>
          <Typography className={'footerMenuSectionTitle'} variant="button" display="block">
            SUPPORT
          </Typography> 
          <ul className={'footerMenuUl'}>
            <li className={'footerMenuItem'}>
              <Link className={'footerMenuLink'} href="/covid-safety">Our COVID-19 Response</Link>
            </li> 
            <li className={'footerMenuItem'}>
              <Link className={'footerMenuLink'} href="/contact-us">Help Center</Link>
            </li>  
          </ul>  
        </section>
    	</div>
      {typeof process.env["NEXT_PUBLIC_HOSTNAME"] !== 'undefined' && process.env["NEXT_PUBLIC_HOSTNAME"] !== 'elopifi.com' 
      ? <Copyright /> 
      : null }
    </Box>
  );
}
// https://icons8.com/icon/set/social-media/material