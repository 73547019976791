import React from 'react';
import Typography from '@material-ui/core/Typography';

import { makeStyles, useTheme } from '@material-ui/styles'; 
import useMediaQuery from '@material-ui/core/useMediaQuery'; 

import InfoOutlined from '@material-ui/icons/InfoOutlined';
import TextsmsIcon from '@material-ui/icons/Textsms';
import EventIcon from '@material-ui/icons/Event';
import ButtonLink from 'components/ButtonLink'; 

export default function VenueActions(destination) {
	const theme = useTheme();
  const matchesXs = useMediaQuery(theme.breakpoints.up('xs'));
  const matchesSm = useMediaQuery(theme.breakpoints.up('sm'));
  const matchesMd = useMediaQuery(theme.breakpoints.up('md'));  
  return (<> 
    <div style={{textAlign: 'center'}}>
      <Typography style={{margin: 0, marginTop: 35, textAlign: 'center', display: 'flex'}} gutterBottom component="h3">
        <InfoOutlined/><span>&nbsp; We have multiple venues/sites available for the 2022-2023 wedding season. Check availability for a Specific Day or request a Free Consultation and start planning today, with no obligation</span> 
      </Typography> <br/><br/> 
      <ButtonLink style={{ 
        fontSize: 14,
        width: !matchesMd ? '100%' : 360,
        letterSpacing: 1,
        padding: '8px 24px', 
        textTransform: 'capitalize'
      }} variant="contained" href={destination?.destination ? `/get-started/availability?destination=${destination.destination}&packageName=CheckAvailability` 
        : "/get-started/availability?packageName=CheckAvailability"} 
      color="primary"> <EventIcon/>&nbsp;&nbsp;&nbsp;Check Availability for a{matchesMd ? ' Specific' : null} Date</ButtonLink>
      <br/><br/>
      <ButtonLink style={{ 
        fontSize: 14,
        width: !matchesMd ? '100%' : 360,
        letterSpacing: 1,
        padding: '8px 24px', 
        textTransform: 'capitalize', 
      }} variant="contained" href={destination?.destination ? `/get-started/availability?destination=${destination.destination}&packageName=Consultation` 
        : "/get-started/availability?packageName=Consultation"} 
      color="secondary"><TextsmsIcon/>&nbsp;&nbsp;&nbsp;Request a Free Consultation</ButtonLink> 
      <br/><br/>

      </div>
  </>);
}
