import React, { useContext } from 'react';
import Typography from '@material-ui/core/Typography'; 
import Logo from 'components/Logo';   

export default function Copyright() {
	let today = new Date(); 

  return (<> 
    <Typography className="footer__copyright" variant="body2" align="center">
        <Logo /> makes you happy :)
    </Typography> 
    <div style={{textAlign: 'center', fontSize: 9, paddingBottom: 20, color: 'rgb(130, 130, 130)'}}>© {today.getFullYear()} {process.env["NEXT_PUBLIC_COMPANY_NAME"]}. All rights reserved.</div>
    { /**
    <div className="footer__social">
      <a href={"https://www.instagram.com/elopifyworld/"} target={"_blank"}>
        <img alt="instagram" src={"data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHg9IjBweCIgeT0iMHB4Igp3aWR0aD0iMjQiIGhlaWdodD0iMjQiCnZpZXdCb3g9IjAgMCAyNCAyNCIKc3R5bGU9IiBmaWxsOiMwMDAwMDA7Ij4gICAgPHBhdGggZD0iTSA4IDMgQyA1LjIzOSAzIDMgNS4yMzkgMyA4IEwgMyAxNiBDIDMgMTguNzYxIDUuMjM5IDIxIDggMjEgTCAxNiAyMSBDIDE4Ljc2MSAyMSAyMSAxOC43NjEgMjEgMTYgTCAyMSA4IEMgMjEgNS4yMzkgMTguNzYxIDMgMTYgMyBMIDggMyB6IE0gMTggNSBDIDE4LjU1MiA1IDE5IDUuNDQ4IDE5IDYgQyAxOSA2LjU1MiAxOC41NTIgNyAxOCA3IEMgMTcuNDQ4IDcgMTcgNi41NTIgMTcgNiBDIDE3IDUuNDQ4IDE3LjQ0OCA1IDE4IDUgeiBNIDEyIDcgQyAxNC43NjEgNyAxNyA5LjIzOSAxNyAxMiBDIDE3IDE0Ljc2MSAxNC43NjEgMTcgMTIgMTcgQyA5LjIzOSAxNyA3IDE0Ljc2MSA3IDEyIEMgNyA5LjIzOSA5LjIzOSA3IDEyIDcgeiBNIDEyIDkgQSAzIDMgMCAwIDAgOSAxMiBBIDMgMyAwIDAgMCAxMiAxNSBBIDMgMyAwIDAgMCAxNSAxMiBBIDMgMyAwIDAgMCAxMiA5IHoiPjwvcGF0aD48L3N2Zz4="}/>
      </a>
      <a href={"https://www.facebook.com/elopifycom"} target={"_blank"}>
        <img alt="facebook" src={"data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHg9IjBweCIgeT0iMHB4Igp3aWR0aD0iMjQiIGhlaWdodD0iMjQiCnZpZXdCb3g9IjAgMCAyNCAyNCIKc3R5bGU9IiBmaWxsOiMwMDAwMDA7Ij4gICAgPHBhdGggZD0iTTEyLDJDNi40NzcsMiwyLDYuNDc3LDIsMTJjMCw1LjAxMywzLjY5Myw5LjE1Myw4LjUwNSw5Ljg3NlYxNC42NUg4LjAzMXYtMi42MjloMi40NzR2LTEuNzQ5IGMwLTIuODk2LDEuNDExLTQuMTY3LDMuODE4LTQuMTY3YzEuMTUzLDAsMS43NjIsMC4wODUsMi4wNTEsMC4xMjR2Mi4yOTRoLTEuNjQyYy0xLjAyMiwwLTEuMzc5LDAuOTY5LTEuMzc5LDIuMDYxdjEuNDM3aDIuOTk1IGwtMC40MDYsMi42MjloLTIuNTg4djcuMjQ3QzE4LjIzNSwyMS4yMzYsMjIsMTcuMDYyLDIyLDEyQzIyLDYuNDc3LDE3LjUyMywyLDEyLDJ6Ij48L3BhdGg+PC9zdmc+"}/>
      </a>
      <a href={"https://twitter.com/elopifycom"} target={"_blank"}>
        <img alt="twitter" src="data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHg9IjBweCIgeT0iMHB4Igp3aWR0aD0iMjQiIGhlaWdodD0iMjQiCnZpZXdCb3g9IjAgMCAyNCAyNCIKc3R5bGU9IiBmaWxsOiMwMDAwMDA7Ij4gICAgPHBhdGggZD0iTTIyLDMuOTk5Yy0wLjc4LDAuNDYzLTIuMzQ1LDEuMDk0LTMuMjY1LDEuMjc2Yy0wLjAyNywwLjAwNy0wLjA0OSwwLjAxNi0wLjA3NSwwLjAyM2MtMC44MTMtMC44MDItMS45MjctMS4yOTktMy4xNi0xLjI5OSBjLTIuNDg1LDAtNC41LDIuMDE1LTQuNSw0LjVjMCwwLjEzMS0wLjAxMSwwLjM3MiwwLDAuNWMtMy4zNTMsMC01LjkwNS0xLjc1Ni03LjczNS00Yy0wLjE5OSwwLjUtMC4yODYsMS4yOS0wLjI4NiwyLjAzMiBjMCwxLjQwMSwxLjA5NSwyLjc3NywyLjgsMy42M2MtMC4zMTQsMC4wODEtMC42NiwwLjEzOS0xLjAyLDAuMTM5Yy0wLjU4MSwwLTEuMTk2LTAuMTUzLTEuNzU5LTAuNjE3YzAsMC4wMTcsMCwwLjAzMywwLDAuMDUxIGMwLDEuOTU4LDIuMDc4LDMuMjkxLDMuOTI2LDMuNjYyYy0wLjM3NSwwLjIyMS0xLjEzMSwwLjI0My0xLjUsMC4yNDNjLTAuMjYsMC0xLjE4LTAuMTE5LTEuNDI2LTAuMTY1IGMwLjUxNCwxLjYwNSwyLjM2OCwyLjUwNyw0LjEzNSwyLjUzOWMtMS4zODIsMS4wODQtMi4zNDEsMS40ODYtNS4xNzEsMS40ODZIMkMzLjc4OCwxOS4xNDUsNi4wNjUsMjAsOC4zNDcsMjAgQzE1Ljc3NywyMCwyMCwxNC4zMzcsMjAsOC45OTljMC0wLjA4Ni0wLjAwMi0wLjI2Ni0wLjAwNS0wLjQ0N0MxOS45OTUsOC41MzQsMjAsOC41MTcsMjAsOC40OTljMC0wLjAyNy0wLjAwOC0wLjA1My0wLjAwOC0wLjA4IGMtMC4wMDMtMC4xMzYtMC4wMDYtMC4yNjMtMC4wMDktMC4zMjljMC43OS0wLjU3LDEuNDc1LTEuMjgxLDIuMDE3LTIuMDkxYy0wLjcyNSwwLjMyMi0xLjUwMywwLjUzOC0yLjMyLDAuNjM2IEMyMC41MTQsNi4xMzUsMjEuNjk5LDQuOTQzLDIyLDMuOTk5eiI+PC9wYXRoPjwvc3ZnPg=="/>
      </a>
      <a href={"https://www.pinterest.com/elopify"} target={"_blank"}>
        <img alt="pinterest" src={"data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHg9IjBweCIgeT0iMHB4Igp3aWR0aD0iMjQiIGhlaWdodD0iMjQiCnZpZXdCb3g9IjAgMCAyNCAyNCIKc3R5bGU9IiBmaWxsOiMwMDAwMDA7Ij48cGF0aCBkPSJNMTIsMkM2LjUsMiwyLDYuNSwyLDEyYzAsNC4yLDIuNiw3LjgsNi40LDkuM2MtMC4xLTAuOC0wLjItMiwwLTIuOWMwLjItMC44LDEuMi01LDEuMi01UzkuMywxMi45LDkuMywxMgljMC0xLjQsMC44LTIuNCwxLjgtMi40YzAuOSwwLDEuMywwLjYsMS4zLDEuNGMwLDAuOS0wLjUsMi4xLTAuOCwzLjNjLTAuMiwxLDAuNSwxLjgsMS41LDEuOGMxLjgsMCwzLjEtMS45LDMuMS00LjYJYzAtMi40LTEuNy00LjEtNC4yLTQuMWMtMi44LDAtNC41LDIuMS00LjUsNC4zYzAsMC45LDAuMywxLjgsMC43LDIuM2MwLjEsMC4xLDAuMSwwLjIsMC4xLDAuM2MtMC4xLDAuMy0wLjIsMS0wLjMsMS4xCWMwLDAuMi0wLjEsMC4yLTAuMywwLjFjLTEuMi0wLjYtMi0yLjQtMi0zLjljMC0zLjIsMi4zLTYuMSw2LjYtNi4xYzMuNSwwLDYuMiwyLjUsNi4yLDUuOGMwLDMuNC0yLjIsNi4yLTUuMiw2LjIJYy0xLDAtMi0wLjUtMi4zLTEuMWMwLDAtMC41LDEuOS0wLjYsMi40Yy0wLjIsMC45LTAuOCwyLTEuMiwyLjZDMTAsMjEuOCwxMSwyMiwxMiwyMmM1LjUsMCwxMC00LjUsMTAtMTBDMjIsNi41LDE3LjUsMiwxMiwyeiI+PC9wYXRoPjwvc3ZnPg=="}/>
      </a>
    </div>
    **/}
  </>);
}
